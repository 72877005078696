import matchSorter from 'match-sorter';
import Data from "../../content/data.json";

const allContacts = Data.tools.map(
  s => (
    {
      name: s.name,
      description: s.description,
      id: s.id,
      link: s.link
    }
  )
);

function getContacts(searchValue, { omitContacts = [], limit } = {})
{
  const remainingContacts = allContacts.filter(
    c => !omitContacts.some(sc => sc && sc === c.id),
  );

  const sortedContacts = searchValue
    ? matchSorter(
      remainingContacts,
      searchValue,
      {
        keys: ['name'],
      }
    )
    : remainingContacts;

  const limitedContacts = limit
    ? sortedContacts.slice(0, limit)
    : sortedContacts;

  return limitedContacts;
}

function fetchContacts(searchValue, {omitContacts, limit, requestId} = {}) {
  return new Promise(
    resolve =>
    {
      setTimeout(() =>
      {
        resolve(
          {
            response:
            {
              data: getContacts(searchValue, {omitContacts, limit}),
              requestId,
            },
          }
        );
      }, 0)
    }
  );
}
  
export { fetchContacts };