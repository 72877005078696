import React from "react";
import Downshift from 'downshift'

import { ContactList } from './contactList';
import { FetchContacts } from './fetchContacts';


class ToolsNav extends React.Component
{
  input = React.createRef();
  state =
  {
    selectedContacts: [ 999 ]
  };

  handleChange = (selectedContact, downshift) =>
  {
    this.setState(
      ({ selectedContacts }) => (
        {
          selectedContacts: [...selectedContacts, selectedContact],
        }
      ),
      () => {
        downshift.reset();
        this.props.onChange(this.state.selectedContacts);
      },
    );
  };

  removeContact(contact)
  {
    this.setState(
      ({ selectedContacts }) => (
        {
          selectedContacts: selectedContacts.filter(c => c !== contact),
        }
      ),
      () =>
      {
        this.input.current.focus();
        this.props.onChange(this.state.selectedContacts);
      },
    );
  };

  handleInputKeyDown = (
    {
      event,
      isOpen,
      selectHighlightedItem,
      highlightedIndex,
      reset,
      inputValue,
    }
  ) =>
  {
    if (event.key === 'Backspace' && !event.target.value)
    {
      // remove the last input
      this.setState(
        ({ selectedContacts }) => (
          {
            selectedContacts: selectedContacts.length
              ? selectedContacts.slice(0, selectedContacts.length - 1)
              : [],
          }
        ),
        () =>
        {
          reset();
          this.props.onChange(this.state.selectedContacts);
        },
      );
    } else if (isOpen && ['Tab', ',', ';'].includes(event.key))
    {
      event.preventDefault();

      if (highlightedIndex != null)
      {
        selectHighlightedItem();
      }
      else
      {
        this.setState(
          ({selectedContacts}) => (
            {
              selectedContacts: [
                ...selectedContacts,
                {
                  id: inputValue.toLowerCase(),
                  email: inputValue,
                  name: inputValue,
                },
              ],
            }
          ),
          () =>
          {
            reset();
            this.props.onChange(this.state.selectedContacts);
          },
        );
      }
    }
  };

  itemToString = i =>
  {
    return i ? (i.name === i.email ? i.name : `${i.name} (${i.email})`) : '';
  };

  render()
  {
    const { selectedContacts } = this.state;

    return (
      <Downshift
        itemToString = { this.itemToString }
        selectedItem = { null }
        onChange = { this.handleChange }
        defaultHighlightedIndex = { 0 }
      >
        {
          (
            {
              getLabelProps,
              getInputProps,
              getItemProps,
              getMenuProps,
              isOpen,
              toggleMenu,
              clearSelection,
              highlightedIndex,
              selectHighlightedItem,
              setHighlightedIndex,
              reset,
              inputValue,
              clearItems,
              setItemCount,
            }
          ) => (
            <div>
              <div
                className = "flex relative items-center flex-wrap"
              >
                <input
                  {
                    ...getInputProps(
                      {
                        id: 'utilitySearchInput',
                        ref: this.input,
                        onKeyDown: event => this.handleInputKeyDown(
                          {
                            event,
                            selectHighlightedItem,
                            highlightedIndex,
                            isOpen,
                            reset,
                            inputValue,
                          }
                        ),
                        placeholder: 'Search the Tools',
                        className: "flex-1 rounded py-2 px-3 outline-none w-full min-w-1/4"
                      }
                    )
                  }
                />
              </div>
              {
                !isOpen ? null : (
                  <ul
                    {
                      ...getMenuProps(
                        {
                          className: "p-0 m-0"
                        }
                      )
                    }
                  >
                    <FetchContacts
                      searchValue = { inputValue }
                      omitContacts = { selectedContacts }
                      onLoaded = { ({ contacts }) =>
                        {
                          clearItems();
                          if (contacts)
                          {
                            setHighlightedIndex(contacts.length ? 0 : null);
                            setItemCount(contacts.length);
                          }
                        }
                      }
                    >
                      {
                        ({ loading, contacts, error }) => (
                          <div
                            className = "absolute bg-white max-h-1/2 shadow border-solid border-1 mt-1 border-gray-600"
                          >
                            {
                              loading ? (
                                <div
                                  className = "p-4 text-center"
                                  style =
                                  {
                                    {
                                      width: document.getElementById('utilitySearchInput').offsetWidth
                                    }
                                  }
                                >
                                  loading...
                                </div>
                              ) : error ? (
                                <div
                                  className = "p-4 text-center"
                                  style =
                                  {
                                    {
                                      width: document.getElementById('utilitySearchInput').offsetWidth
                                    }
                                  }
                                >
                                  error...
                                </div>
                              ) : contacts.length ? (
                                <ContactList
                                  highlightedIndex = { highlightedIndex }
                                  getItemProps = { getItemProps }
                                  contacts = { contacts }
                                />
                              ) : (
                                <div
                                  className = "p-4 text-center"
                                  style =
                                  {
                                    {
                                      width: document.getElementById('utilitySearchInput').offsetWidth
                                    }
                                  }
                                >
                                  no results...
                                </div>
                              )
                            }
                          </div>
                        )
                      }
                    </FetchContacts>
                  </ul>
                )
              }
            </div>
          )
        }
      </Downshift>
    );
  }
}

export default ToolsNav;
