import React from "react";
import chestIcon from "../images/chest-icon.svg";

const Footer = () =>
{
  return (
    <footer
      id = "footer"
      className = "bg-blue-700"
    >
      <nav
        className = "max-w-4xl mx-auto p-4 text-sm"
      >
        <p>
          <img
            alt = "Chest"
            className = "fill-current h-8 w-8 mx-auto"
            height = "54"
            viewBox = "0 0 54 54"
            width = "54"
            src = { chestIcon }
          />
        </p>
      </nav>
    </footer>
  );
}

export default Footer;