import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";

import chestIcon from "../images/chest-icon.svg";
import ToolsNav from "./toolsNav";
import { isLoggedIn } from "../utils/auth";

function Header(props) {
  const [isExpanded, toggleExpansion] = useState(false);
  const [selected, setSelected] = useState({});

  const { site } = useStaticQuery(graphql`
    query HeaderSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  let navLinks = [];
  
  if (!props.isAdminPage)
  {
    navLinks = [
      {
        route: `/`,
        title: `Home`
      },
      {
        route: `/about`,
        title: `About`
      },
      {
        route: `/tools`,
        title: `Tools`
      }
    ];
  }
  else if (isLoggedIn())
  {
    navLinks = [
      {
        route: `/admin`,
        title: `Dashboard`
      },
      {
        route: `/admin/tools`,
        title: `Tools Administration`
      },
      {
        route: `/admin/profile`,
        title: `Update Details`
      },
      {
        route: `/admin/logout`,
        title: `Logout`
      }
    ];
  }

  return (
    <header
      id = "header"
      className = "bg-teal-700"
    >
      <div className="flex flex-wrap items-center justify-between max-w-4xl mx-auto p-4 md:p-8">
        <Link
          className = "flex items-center no-underline text-white"
          to = { (props.isAdminPage && isLoggedIn()) ? "/admin" : "/" }
        >
          <img
            alt = "Chest"
            className = "fill-current h-8 mr-2 w-8"
            height = "54"
            viewBox = "0 0 54 54"
            width = "54"
            src = { chestIcon }
          />
          <span className="font-bold text-lg tracking-tight">
            { `${site.siteMetadata.title} ${(props.isAdminPage && isLoggedIn()) ? "Admin" : ""}` }
          </span>
        </Link>

        {
          navLinks.length > 0 &&
            <>
              <button
                className="block md:hidden border border-white flex items-center px-3 py-2 rounded text-white"
                onClick={() => toggleExpansion(!isExpanded)}
              >
                <svg
                  className="fill-current h-3 w-3"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>

              <nav
                className =
                {
                  `${ isExpanded ? `block` : `hidden` }
                  md:block
                  md:flex
                  md:items-center
                  w-full
                  md:w-auto`
                }
              >
                {
                  navLinks.map(
                    link => (
                      <Link
                        className = "block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-white"
                        key = { link.title }
                        to = { link.route }
                      >
                        { link.title }
                      </Link>
                    )
                  )
                }
              </nav>
            </>
        }
      </div>

      {
        !props.isAdminPage &&
          <div
            className = "flex flex-col flex-1 md:justify-center max-w-4xl mx-auto w-full px-4 pb-2"
          >
            <ToolsNav
              onChange = { selection => setSelected({ selection }) }
            />
          </div>
      }
    </header>
  );
}

export default Header;
