import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

const SEO = (props) =>
{
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `);

  const metaDescription = props.description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes =
      {
        {
          lang: props.lang
        }
      }
      meta =
      {
        [
          {
            name: `description`,
            content: metaDescription
          },
          {
            property: `og:title`,
            content: props.title
          },
          {
            property: `og:description`,
            content: metaDescription
          },  
          {
            property: `og:type`,
            content: `website`
          },
          {
            name: `twitter:card`,
            content: `summary`
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author
          },
          {
            name: `twitter:title`,
            content: props.title
          },
          {
            name: `twitter:description`,
            content: metaDescription
          }
        ]
        .concat(
          props.keywords.length > 0
            ? {
                name: `keywords`,
                content: props.keywords.join(`, `)
              }
            : []
        )
        .concat(props.meta)
      }
      title = { props.title }
      titleTemplate = { `%s${props.isAdmin ? " | Admin" : ""} | ${site.siteMetadata.title}` }
    />
  );
}

SEO.defaultProps =
{
  lang: `en`,
  keywords: [],
  meta: []
};

SEO.propTypes =
{
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired
};

export default SEO;
