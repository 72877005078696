import React from "react";
import { List } from 'react-virtualized';
import { Link } from "gatsby";

import toolsIllustration from "../images/tools-illustration.svg";
import PenaltyUnitCalculatorIllustration from "../images/penalty-unit-calculator-illustration.svg";
import PreSentenceDetentionCalculatorIllustration from "../images/pre-sentence-detention-calculator-illustration.svg";

function ContactList({ highlightedIndex, getItemProps, contacts })
{
  const rowHeight = 50;
  const fullHeight = contacts.length * rowHeight;

  const rowRenderer = ({ key, index, style }) => (
    <li
      key = { contacts[index].id }
      {
        ...getItemProps(
          {
            item: contacts[index],
            index,
            style,
            className: `absolute top-0 w-full h-12 left-0 cursor-pointer px-4 ${ highlightedIndex === index ? 'bg-gray-400' : 'bg-white' }`
          }
        )
      }
    >
      <Link
        to = { contacts[index].link }
      >
        <div
          className = "flex justify-center"
        >
          <img
            alt = "Tools"
            className = "flex-none h-12 py-2"
            src =
            {
              (
                () =>
                {
                  switch (contacts[index].id)
                  {
                    case 1:
                      return PenaltyUnitCalculatorIllustration;
                    case 2:
                      return PreSentenceDetentionCalculatorIllustration;
                    default:
                      return toolsIllustration;
                  }
                }
              )()
            }
          />
          <div
            className = "flex-1"
          >
            <div>
              { contacts[index].name }
            </div>
            <div
              className = "text-sm	ml-1"
            >
              { contacts[index].description }
            </div>
          </div>
        </div>
      </Link>
    </li>
  );

  return (
    <List
      width = { document.getElementById('utilitySearchInput').offsetWidth }
      scrollToIndex = { highlightedIndex || 0 }
      height = { fullHeight > 280 ? 280 : fullHeight }
      rowCount = { contacts.length }
      rowHeight = { rowHeight }
      rowRenderer = { ({ key, index, style }) => rowRenderer({ key, index, style }) }
    />
  )
};

export { ContactList }